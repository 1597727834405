<template>
    <form v-if="!formSubmitted" @submit.prevent="sendAboutCeo" class="form form--ceo">
        <div class="form__item">
            <label for="fio" class="form__label">Фамилия Имя Отчество <sup>*</sup></label>
            <input type="text" class="form__input" id="fio" placeholder="Представьтесь, пожалуйста"
                v-model="aboutCeoForm.full_name" :class="{ 'is-invalid': aboutCeoForm.errors.has('full_name') }">
            <HasError :form="aboutCeoForm" field="full_name"></HasError>
        </div>
        <div class="form__item">
            <label for="place" class="form__label">Укажите ваш город <sup>*</sup></label>
            <input type="text" class="form__input" id="place" placeholder="Москва" v-model="aboutCeoForm.city"
                :class="{ 'is-invalid': aboutCeoForm.errors.has('city') }">
            <HasError :form="aboutCeoForm" field="city"></HasError>
        </div>
        <div class="form__item form__item--msg">
            <label class="form__label">Категория вопроса <sup>*</sup></label>
            <select class="form__select select" v-model="aboutCeoForm.question_category"
                :class="{ 'is-invalid': aboutCeoForm.errors.has('question_category') }">
                <option value="" disabled selected>&nbsp;</option>
                <option value="Отзыв о работе сотрудника">Отзыв о работе сотрудника</option>
                <option value="Отзыв о работе компании">Отзыв о работе Компании</option>
                <option value="Благодарность">Благодарность</option>
                <option value="Предложение о сотрудничестве">Предложение о сотрудничестве</option>
                <option value="Вопросы по получению займов, справочная информация">Вопросы по получению займов,
                    справочная информация
                </option>
            </select>
            <HasError :form="aboutCeoForm" field="question_category"></HasError>
        </div>
        <div class="form__item">
            <label for="msg" class="form__label">Текст сообщения <sup>*</sup></label>
            <textarea class="form__textarea" id="msg" placeholder="Введите свое сообщение"
                v-model="aboutCeoForm.message_body"
                :class="{ 'is-invalid': aboutCeoForm.errors.has('message_body') }"></textarea>
            <HasError :form="aboutCeoForm" field="message_body"></HasError>
        </div>
        <div class="form__item">
            <label for="email" class="form__label">Электронная почта <sup>*</sup></label>
            <input type="email" class="form__input" id="email" placeholder="Напишите адрес своей электронной почты"
                v-model="aboutCeoForm.email" :class="{ 'is-invalid': aboutCeoForm.errors.has('email') }">
            <HasError :form="aboutCeoForm" field="email"></HasError>
        </div>
        <!-- Компонент для ввода номера телефона -->
        <div class="form__item">

            <PhoneInputComponent :phone="aboutCeoForm.phone" :phone_code="aboutCeoForm.phone_code"
                @update:phone="aboutCeoForm.phone = $event" @update:phone_code="aboutCeoForm.phone_code = $event" />
            <HasError :form="aboutCeoForm" field="phone"></HasError>
        </div>

        <!-- Компонент для ввода SMS-кода -->
        <div class="form__item">

            <SmsCodeComponent :phone="aboutCeoForm.phone" :phone_code="aboutCeoForm.phone_code"
                @update:smsCode="aboutCeoForm.sms_code = $event"
                @update:encryptedSmsCode="aboutCeoForm.encrypted_sms_code = $event" />
            <HasError :form="aboutCeoForm" field="sms_code"></HasError>
        </div>
        <div class="form__item">
            <div class="g-recaptcha" :data-sitekey="siteKey" data-expired-callback="recaptcha_expired"
                data-callback="recaptcha_callback"></div>
        </div>
        <hr>
       <!-- <div class="form__item">
            <label class="form__check check">
                <input type="checkbox" class="check__input" checked v-model="agreeWithTerm">
                <span class="check__txt">Я согласен с обработкой <a href="#">персональных данных</a></span>
            </label>
        </div>-->
        <div class="form__item">
            <!-- Обновленный атрибут :disabled -->
            <button class="form__btn btn btn--green" type="submit" :disabled="!isFormValid">ОТПРАВИТЬ</button>
        </div>
    </form>
    <!-- Сообщение отображается после успешной отправки -->
    <div v-else class="ceo-form__txt">
        <p style="
    color: rgb(49, 49, 49);
    font-size: 1.3em;
    font-weight: 600;
    background-color: rgb(237, 237, 237);
    width: 100%;
    margin: 0px auto;
    height: 6em;
    padding-top: 1em;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-left: 0em;
    padding: 3em;
            ">Ваш запрос принят. <br>  С Вами свяжутся в ближайшее время!</p>
    </div>
  
</template>

<script>
import SmsCodeComponent from './SmsCodeComponent.vue';
import PhoneInputComponent from './PhoneInputComponent.vue';


export default {
    name: "AboutCeoForm",
    components: {
        SmsCodeComponent,
        PhoneInputComponent,

    },
    data() {
        return {
            siteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY,

            aboutCeoForm: new Form({
                token: '',
                id: null,
                full_name: '',
                city: '',
                question_category: '',
                message_body: '',
                email: '',
                phone: '',
                phone_code: '+7',
                sms_code: '',
                encrypted_sms_code: '',
                agree: true,
            }),
            agreeWithTerm: false,
            formSubmitted: false,
        }
    },
    computed: {
        isFormValid() {
            return this.aboutCeoForm.full_name &&
                this.aboutCeoForm.city &&
                this.aboutCeoForm.question_category &&
                this.aboutCeoForm.message_body &&
                this.aboutCeoForm.email &&
                this.aboutCeoForm.phone &&
                this.aboutCeoForm.sms_code &&
                this.aboutCeoForm.encrypted_sms_code //&&
                //this.agreeWithTerm;
        }
    },
    methods: {
        async sendAboutCeo() {
            if (!this.isFormValid) {
                this.$snotify.warning('Пожалуйста, заполните все обязательные поля.', { timeout: 6000 });
                return;
            }

            try {
                // Собираем данные для отправки
                const formData = {
                    full_name: this.aboutCeoForm.full_name,
                    city: this.aboutCeoForm.city,
                    question_category: this.aboutCeoForm.question_category,
                    message_body: this.aboutCeoForm.message_body,
                    email: this.aboutCeoForm.email,
                    phone: this.aboutCeoForm.phone.replace(/[\s\-\(\)]/g, ''),
                    phone_code: this.aboutCeoForm.phone_code.replace('+', ''),
                    sms_code: String(this.aboutCeoForm.sms_code),
                    encrypted_sms_code: this.aboutCeoForm.encrypted_sms_code,
                    token: this.aboutCeoForm.token,
                    agree: this.agreeWithTerm,
                };

                // Отправляем данные на сервер
                const response = await this.aboutCeoForm.post('/send-mb-about-ceo-form', formData);

                if (response.data.success) {
                    this.$snotify.success(response.data.message, { timeout: 6000 });
                    this.formSubmitted = true;
                    this.aboutCeoForm.reset();
                } else {
                    this.$snotify.warning(response.data.message, { timeout: 6000 });
                }
            } catch (error) {
                console.error('Ошибка при отправке формы:', error);
                if (error.response && error.response.status === 422) {
                    // Устанавливаем ошибки в форму
                    this.aboutCeoForm.errors.set(error.response.data.errors);
                    this.$snotify.error('Пожалуйста, исправьте ошибки в форме.', { timeout: 6000 });
                } else {
                    // Другие ошибки
                    this.$snotify.error(error.response.data.message || 'Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова позже.', { timeout: 3000 });
                }
            }
        },
        scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // плавная прокрутка
        });
    }
    },
    
}
</script>

<style scoped>
::v-deep .phone-separate {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}
::v-deep .phone-separate-label{
    width: 100%;
}

::v-deep .phone-separate-fields {
    margin-left: 0%;
    width: 100%;
}
::v-deep .phone-separate-fields div {
    width: 100%;
}

::v-deep .phone-separate-selector {
    padding-left: 1em;
    width: 21%;
}

::v-deep .separate-phone-field {
    width: 100%;
}

::v-deep .sms_item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

::v-deep .separate-sms-code {
    margin-left: 0%;
}



::v-deep .sms-phone-select {
    width: 14%;
    margin-right: 1px;
    margin-left: 5%;
}

::v-deep .sms-phone-input {
    width: 50%;
}

::v-deep .sms-code-input {
    margin-right: 1% !important;
    margin-left: 6% !important;
    width: 30% !important;
}

@media only screen and (max-width: 768px) {

    ::v-deep .phone-separate-selector {
        width:30%;
    }

    
}
</style>


