<template>
    <div class="captcha-component" style="margin-bottom: 1em;">
      <p><strong>Подтвердите, что вы не робот</strong></p>
      <p>Проверка чувствительна к регистру (заглавные и строчные буквы)</p>
      <div class="captcha-wrapper">
        <img
          :src="captchaImage"
          alt="Captcha"
          class="captcha-image"
          @click="refreshCaptcha"
        />
        <input
          type="text"
          v-model="captchaInput"
          class="captcha-input"
          placeholder="Введите код капчи"
          @input="emitCaptchaData"
        />
        <p style="margin-top:0.5em">Введите текст с изображения и нажмите "Получить код"</p>
      </div>
      <p v-if="errorMessage" class="captcha-error">{{ errorMessage }}</p>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "CaptchaComponent",
    emits: ["captcha-data-updated"],
    data() {
      return {
        captchaImage: null,
        captchaInput: "",
        captchaKey: "",
        errorMessage: "",
      };
    },
    methods: {
      async loadCaptcha() {
        try {
          const response = await axios.get("/api/captcha");
          this.captchaImage = response.data.captcha;
          this.captchaKey = response.data.key;
  
          
          this.emitCaptchaData(); // Автоматическое обновление данных
        } catch (error) {
          console.error("Ошибка загрузки капчи:", error);
          this.errorMessage = "Не удалось загрузить капчу. Попробуйте позже.";
        }
      },
      async refreshCaptcha() {
        this.captchaInput = "";
        this.errorMessage = "";
  
        await this.loadCaptcha();
  
        if (!this.captchaKey) {
          this.errorMessage = "Капча не может быть загружена. Попробуйте позже.";
        }
      },
      emitCaptchaData() {
        
        this.$emit("captcha-data-updated", {
          captcha_key: this.captchaKey,
          input: this.captchaInput,
        });
      },
    },
    mounted() {
      this.loadCaptcha();
      this.emitCaptchaData(); // Убедитесь, что данные передаются при загрузке капчи
    },
    watch: {
      captchaKey() {
        
        this.emitCaptchaData(); // Передаём ключ при каждом обновлении
      },
    },
  };
  </script>
  