<template>
    <div>
        <h3>РАССЧИТАТЬ ЗАЁМ</h3>
        <p>М Булак - надежная помощь соотечественникам вдали от Родины!</p>


        <div class="row calc-row">
            <div class="col-md-8">
                <div class="form-section">

                    <!-- <div class="form-group">
                        <div class="radio-row">
                            <div class="form-check" v-for="(loanType, index) in loanTypes" :key="index">
                                <input type="radio" :id="'radio' + index" name="loanType" class="form-check-input"
                                    :value="loanType.value" v-model="selectedLoanType">
                                <label class="form-check-label" :for="'radio' + index">
                                    {{ loanType.text }}
                                    <br v-if="loanType.subText">
                                    <span class="gray-calc-text-sm" v-if="loanType.subText">{{ loanType.subText
                                        }}</span>
                                </label> 
                            </div>
                        </div>
                    </div> -->


                    <div class="loan-description">
                        <p>{{ loanDescription }}</p>
                    </div>

                    <div class="form-group slider-group loan-select">
                        <label for="loanType">Выберите тип займа</label>
                        <div class="custom-select" @click="toggleDropdown">
                            <div class="select-header">
                                <span>{{ selectedLoanTypeText }}</span>
                                <i :class="dropdownOpen ? 'arrow-up' : 'arrow-down'"></i>
                            </div>
                            <ul v-if="dropdownOpen" class="select-dropdown">
                                <li v-for="(loanType, index) in loanTypes" :key="index"
                                    @click="selectLoanType(loanType.value)">
                                    {{ loanType.text }}
                                    <span v-if="loanType.subText" class="sub-text">({{ loanType.subText }})</span>
                                </li>
                            </ul>
                        </div>
                        <!--<select v-model="selectedLoanType" class="form-select loan-select-dropdown">
                            <option v-for="(loanType, index) in loanTypes" :key="index" :value="loanType.value">
                                {{ loanType.text }}
                                <span v-if="loanType.subText">({{ loanType.subText }})</span>
                            </option>
                        </select>-->
                    </div>
                    <div class="form-group slider-group">
                        <label for="amount">Мне нужно <span class="range-value">{{ amount }} р.</span></label>
                        <div class="range-slider">
                            <input type="range" class="form-range" id="amount" :min="minAmount" :max="maxAmount"
                                step="1000" v-model="amount" @input="updateResult"
                                :style="getRangeStyle(amount, minAmount, maxAmount)">

                            <!-- Статичный блок для максимальной суммы -->
                            <span class="irs-single static-value">{{ maxAmount }} р.</span>
                        </div>
                    </div>
                    <div class="form-group slider-group">
                        <label for="term">Сроком на <span class="range-value">{{ term }} месяцев</span></label>
                        <div class="range-slider">
                            <input type="range" class="form-range" id="term" :min="minTerm" :max="maxTerm" step="1"
                                v-model="term" @input="updateResult" :style="getRangeStyle(term, minTerm, maxTerm)">

                            <!-- Статичный блок для максимального срока -->
                            <span class="irs-single static-value">{{ maxTerm }} мес.</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-4 result-box-container">
                <div class="result-box">
                    <div class="form-group">
                        <!--<div v-if="selectedLoanType === 'TYPE6' || selectedLoanType === 'TYPE8' || selectedLoanType === 'TYPE9'"
                            class="form-group text-center">
                            <label for="level">Выберите уровень</label>
                            <select v-model="selectedLevel" class="form-select">
                                <option v-for="level in levels" :value="level.value">{{ level.text }}</option>
                            </select>
                        </div>-->
                        <div v-if="selectedLoanType === 'TYPE6' || selectedLoanType === 'TYPE7' || selectedLoanType === 'TYPE8' || selectedLoanType === 'TYPE9'"
                            class="level-select">
                            <label for="level">Сколько у вас баллов?</label>
                            <div class="custom-select level-select-drop" @click="toggleDropdownLevel">
                                <div class="select-header">
                                    <span>{{ selectedLevelText }}</span>
                                    <i :class="dropdownOpenLevel ? 'arrow-up' : 'arrow-down'"></i>
                                </div>
                                <ul v-if="dropdownOpenLevel" class="select-dropdown">
                                    <li v-for="(level, index) in filteredLevels" :key="index" @click="selectLevel(level.value)">
                                        {{ level.text }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <p class="calc-heading">Ежемесячный платёж от:</p>

                    <p class="new-price">{{ result }} руб. <span class="new-price-span" v-if="showNewPrice"></span></p>


                    <a :href="`/loans/add?loan_sum=${amount}&loan_term=${term}`" class="calc-button">
                        ПОЛУЧИТЬ ДЕНЬГИ
                    </a>
                </div>
                <p style="font-size: 0.7em;width: 99%; margin-bottom: 0.2em !important;" class="mt-2">*Финальный расчёт
                    ежемесячного платежа и максимальной суммы Вашего займа
                    производится при выдаче займа и может отличаться от указанного.</p>
                <p style="font-size: 0.7em;width: 99%;margin-bottom: 0.2em;">**Оценивайте свои финансовые возможности и
                    риски</p>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'LoanCalculator',
    data() {
        return {
            amount: 15000,
            term: 3,
            selectedLevel: 0,
            selectedLoanType: 'TYPE6',
            minAmount: 5000,
            maxAmount: 75000,
            minTerm: 3,
            maxTerm: 12,
            levels: [
                { value: 0, text: '0 баллов' },
                { value: 4, text: '4 балла' },
                { value: 7, text: '7 баллов' },
                { value: 10, text: '10 баллов' },
                { value: 13, text: '13 баллов' },
                { value: 19, text: '19 баллов' },
                { value: 25, text: '25 баллов' },
                { value: 31, text: '31 балл' },
                { value: 37, text: '37 баллов' },
                { value: 43, text: '43 балла' },
                { value: 49, text: '49 баллов' },
                { value: 55, text: '55 баллов' },
                { value: 60, text: '61+ баллов' },
            ],
            dropdownOpen: false, // Управляем состоянием выпадающего списка
            dropdownOpenLevel: false, // Добавляем это свойство для уровня
            loanTypes: [
                { value: 'TYPE5', text: 'На патент' },
                { value: 'TYPE6', text: 'Надёжный клиент', subText: '' },
                { value: 'TYPE7', text: 'На покупку авто' },
                { value: 'TYPE8', text: 'Под залог авто', subText: '' },
                { value: 'TYPE9', text: 'Для самозанятых', subText: '' },
            ],
            levelSettings: {
                0: { procent: 8.6, maxAmount: 75000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                4: { procent: 8.1, maxAmount: 85000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                7: { procent: 7.6, maxAmount: 100000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                10: { procent: 7.3, maxAmount: 100000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                13: { procent: 7.0, maxAmount: 100000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                19: { procent: 6.7, maxAmount: 100000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                25: { procent: 6.4, maxAmount: 100000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                31: { procent: 6.1, maxAmount: 100000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                37: { procent: 5.8, maxAmount: 100000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                43: { procent: 5.5, maxAmount: 100000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                49: { procent: 5.2, maxAmount: 100000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                55: { procent: 4.9, maxAmount: 100000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                60: { procent: 4.6, maxAmount: 100000, minAmount: 15000, minTerm: 3, maxTerm: 12 }
            },
            levelSettingsType7: {
                0: { procent: 6.0, maxAmount: 500000, minAmount: 90000, minTerm: 6, maxTerm: 12 },
                10: { procent: 5.7, maxAmount: 500000, minAmount: 90000, minTerm: 6, maxTerm: 18 },
                19: { procent: 5.4, maxAmount: 500000, minAmount: 90000, minTerm: 6, maxTerm: 18 }
               
            },
            levelSettingsType8: {
                0: { procent: 8.1, maxAmount: 150000, minAmount: 55000, minTerm: 6, maxTerm: 12 },
                4: { procent: 8.1, maxAmount: 200000, minAmount: 55000, minTerm: 6, maxTerm: 12 },
                7: { procent: 7.6, maxAmount: 200000, minAmount: 55000, minTerm: 6, maxTerm: 12 },
                10: { procent: 7.3, maxAmount: 250000, minAmount: 55000, minTerm: 6, maxTerm: 18 },
                13: { procent: 7.0, maxAmount: 250000, minAmount: 55000, minTerm: 6, maxTerm: 18 },
                19: { procent: 6.7, maxAmount: 300000, minAmount: 55000, minTerm: 6, maxTerm: 18 },
                25: { procent: 6.4, maxAmount: 300000, minAmount: 55000, minTerm: 6, maxTerm: 24 },
                31: { procent: 6.1, maxAmount: 300000, minAmount: 55000, minTerm: 6, maxTerm: 24 },
                37: { procent: 5.8, maxAmount: 350000, minAmount: 55000, minTerm: 6, maxTerm: 24 },
                43: { procent: 5.5, maxAmount: 350000, minAmount: 55000, minTerm: 6, maxTerm: 24 },
                49: { procent: 5.2, maxAmount: 400000, minAmount: 55000, minTerm: 6, maxTerm: 24 },
                55: { procent: 4.9, maxAmount: 450000, minAmount: 55000, minTerm: 6, maxTerm: 24 },
                60: { procent: 4.6, maxAmount: 500000, minAmount: 55000, minTerm: 6, maxTerm: 24 }
            },
            levelSettingsType9: {
                0: { procent: 8.6, maxAmount: 80000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                4: { procent: 8.1, maxAmount: 90000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                7: { procent: 7.6, maxAmount: 110000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                10: { procent: 7.1, maxAmount: 120000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                13: { procent: 6.6, maxAmount: 150000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                19: { procent: 6.1, maxAmount: 200000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                25: { procent: 5.7, maxAmount: 200000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                31: { procent: 5.3, maxAmount: 200000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                37: { procent: 4.9, maxAmount: 200000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                43: { procent: 4.5, maxAmount: 200000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                49: { procent: 4.1, maxAmount: 200000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                55: { procent: 3.7, maxAmount: 200000, minAmount: 15000, minTerm: 3, maxTerm: 12 },
                60: { procent: 3.3, maxAmount: 200000, minAmount: 15000, minTerm: 3, maxTerm: 12 }
            },
            procent: 9.5,
            oldProcent: 9.5,
            oldPrice: 0,
            result: 0,
            savings: 0
        };
    },
    computed: {
        showOldPrice() {
            return this.selectedLoanType === 'TYPE6' && this.selectedLevel != 0;
        },
        showSavings() {
            return this.selectedLoanType === 'TYPE6' && this.selectedLevel != 0;
        },
        showNewPrice() {
            return this.selectedLoanType === 'TYPE6' && this.selectedLevel != 0;
        },
        selectedLoanTypeText() {
            const selected = this.loanTypes.find(loan => loan.value === this.selectedLoanType);
            return selected ? selected.text : 'Выберите тип займа';
        },
        selectedLevelText() {
            const selected = this.levels.find(level => level.value === this.selectedLevel);
            return selected ? selected.text : 'Выберите уровень';
        },
        loanDescription() {
            switch (this.selectedLoanType) {
                case 'TYPE5':
                    return 'Заём на получение патента';
                case 'TYPE6':
                    return 'Программа "Надёжный клиент" - повышайте уровень и улучшайте условия';
                case 'TYPE7':
                    return 'Заём на покупку автомобиля - получите большую сумму под залог покупаемого авто';
                case 'TYPE8':
                    return 'Заём под залог автомобиля. Большие суммы, быстрая выдача.';
                case 'TYPE9':
                    return 'Заём для самозанятых - получите до 200 000';
                default:
                    return 'Выберите подходящий тип займа для расчёта.';
            }
        },
        filteredLevels() {
            // Определяем, какие настройки использовать
            let settings = {};
            switch (this.selectedLoanType) {
                case 'TYPE6':
                    settings = this.levelSettings;
                    break;
                case 'TYPE7':
                    settings = this.levelSettingsType7;
                    break;
                case 'TYPE8':
                    settings = this.levelSettingsType8;
                    break;
                case 'TYPE9':
                    settings = this.levelSettingsType9;
                    break;
                default:
                    return this.levels; // Если настроек нет, возвращаем все уровни
            }

            // Фильтруем уровни, которые есть в настройках
            return this.levels.filter(level => settings.hasOwnProperty(level.value));
        }
        

    },
    methods: {
        handleClickOutside(event) {
            const loanSelect = this.$el.querySelector('.loan-select .custom-select');
            const levelSelect = this.$el.querySelector('.level-select .custom-select');

            // Закрываем выпадающий список типов займов при клике вне
            if (loanSelect && !loanSelect.contains(event.target)) {
                this.dropdownOpen = false;
            }

            // Закрываем выпадающий список уровней при клике вне
            if (levelSelect && !levelSelect.contains(event.target)) {
                this.dropdownOpenLevel = false;
            }
        },
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
            if (this.dropdownOpen) {
                this.dropdownOpenLevel = false; // Закрываем другой выпадающий список
            }
        },
        toggleDropdownLevel() {
            this.dropdownOpenLevel = !this.dropdownOpenLevel;
            if (this.dropdownOpenLevel) {
                this.dropdownOpen = false; // Закрываем основной выпадающий список
            }
        },
        selectLoanType(value) {
            this.selectedLoanType = value;
            this.dropdownOpen = false; // Закрываем выпадающий список после выбора
        },
        selectLevel(value) {
            const validLevel = this.filteredLevels.find(level => level.value === value);
            if (validLevel) {
                this.selectedLevel = value;
            }
            this.dropdownOpenLevel = false; // Закрываем выпадающий список после выбора
            },
        goToAnketa() {

            this.$router.push({
                path: '/loans/add',
                query: {
                    loan_sum: this.amount,
                    loan_term: this.term
                }
            });
        },
        updateRangeBackground() {
            const ranges = this.$el.querySelectorAll('input[type="range"]');
            ranges.forEach(range => {
                const value = ((range.value - range.min) / (range.max - range.min)) * 100;
                range.style.setProperty('--value', `${value}%`);
            });
        },
        getRangeStyle(value, min, max) {
            const percentage = ((value - min) / (max - min)) * 100;
            return {
                '--value': `${percentage}%`
            };
        },
        Count_Loan_Payment_Amount(money, month, procent) {
            procent = procent / 100;
            const result = (money * procent) / (1 - Math.pow(1 + procent, -month));
            return Math.round(result);
        },
        updateResult() {
            let settings = null;
            switch (this.selectedLoanType) {
                case 'TYPE5':
                    this.procent = 9.5;
                    this.maxAmount = 40000;
                    this.minAmount = 5000;
                    this.minTerm = 3;
                    this.maxTerm = 12;
                    break;
                case 'TYPE6':
                    settings = this.levelSettings[this.selectedLevel];
                    this.procent = settings.procent;
                    this.maxAmount = settings.maxAmount;
                    this.minAmount = settings.minAmount;
                    this.minTerm = settings.minTerm;
                    this.maxTerm = settings.maxTerm;
                    break;
                case 'TYPE7':
                    settings = this.levelSettingsType7[this.selectedLevel];
                    this.procent = settings.procent;
                    this.maxAmount = settings.maxAmount;
                    this.minAmount = settings.minAmount;
                    this.minTerm = settings.minTerm;
                    this.maxTerm = settings.maxTerm;
                    break;
                case 'TYPE8':
                    settings = this.levelSettingsType8[this.selectedLevel];
                    this.procent = settings.procent;
                    this.maxAmount = settings.maxAmount;
                    this.minAmount = settings.minAmount;
                    this.minTerm = settings.minTerm;
                    this.maxTerm = settings.maxTerm;
                    break;

                case 'TYPE9':
                    settings = this.levelSettingsType9[this.selectedLevel];
                    this.procent = settings.procent;
                    this.maxAmount = settings.maxAmount;
                    this.minAmount = settings.minAmount;
                    this.minTerm = settings.minTerm;
                    this.maxTerm = settings.maxTerm;
                    break;

            }

            // Обновляем amount и term в соответствии с новыми min и max
            this.amount = Math.min(Math.max(this.amount, this.minAmount), this.maxAmount);
            this.term = Math.min(Math.max(this.term, this.minTerm), this.maxTerm);

            // Далее ваш существующий код для расчета платежей
            const monthly_payment = this.Count_Loan_Payment_Amount(this.amount, this.term, this.procent);
            const old_payment = this.Count_Loan_Payment_Amount(this.amount, this.term, this.oldProcent);
            const savings = Math.round((old_payment - monthly_payment) * this.term);

            this.result = monthly_payment;
            this.oldPrice = old_payment;
            this.savings = savings;
        },
        resetValues() {
            let settings = {};
            switch (this.selectedLoanType) {
                case 'TYPE5':
                    settings = { minAmount: 5000, maxAmount: 40000, minTerm: 3, maxTerm: 12 };
                    break;
                case 'TYPE6':
                    settings = this.levelSettings[this.selectedLevel];
                    break;
                case 'TYPE7':
                settings = this.levelSettingsType7[this.selectedLevel];
                    break;
                case 'TYPE8':
                    settings = this.levelSettingsType8[this.selectedLevel];
                    break;
                case 'TYPE9':
                    settings = this.levelSettingsType9[this.selectedLevel];
                    break;
            }

            this.minAmount = settings.minAmount;
            this.maxAmount = settings.maxAmount;
            this.minTerm = settings.minTerm;
            this.maxTerm = settings.maxTerm;

            // Проверяем и корректируем значения amount и term
            this.amount = Math.min(Math.max(this.amount, this.minAmount), this.maxAmount);
            this.term = Math.min(Math.max(this.term, this.minTerm), this.maxTerm);

            this.updateResult();
        }


    },
    watch: {
        selectedLoanType(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.resetValues();
            }
        },
        selectedLevel(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.updateResult();
            }
        },
        amount(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.updateRangeBackground();
            }
        },
        term(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.updateRangeBackground();
            }
        }
    },

    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        this.updateResult();
        this.updateRangeBackground();
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    }
}
</script>

<style scoped>
.calc-row {
    background-color: #F5F5F5;
    border-radius: 22px;
    padding: 0.8em;
}

.form-section {
    padding: 20px;
    border: none;


}

.slider-group {
    background-color: white;
    padding: 1.3em 1em 2em 1em;
    border-radius: 17px;
    margin-bottom: 1em !important;
}

.slider-group label {
    margin-left: 1em;
}

.form-section h3 {
    font-weight: bold;
}

.form-section p {
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 2rem;
}

.form-group {
    display: block;
}

.form-group label {
    font-weight: bold;
    display: block;
}

.level-dropdown {
    display: block;
    margin-bottom: 20px;
}

.level-dropdown select {
    border: 2px solid #ffc107 !important;
    border-radius: 3em;
    padding: 5px 10px;
}

.form-group input[type="number"] {
    width: 80px;
    display: inline-block;
    text-align: center;
    border: none;
    background: transparent;
    font-weight: bold;
    font-size: 1.2rem;
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;
    width: 50%;
}

.loan-select {
    display: flex;


}

.loan-select-dropdown {
    margin-left: 1em;
}

.form-check-input:focus {
    border-color: none !important;
    box-shadow: none !important;
}

.form-check-input[type=radio] {
    appearance: none;
    border-radius: 50%;
    width: 20px;
    height: 22px;
    margin-right: 0.8em;
    display: inline-block;
    border: 2px solid #777777;
    position: relative;
}

.form-check-input:checked {
    background-color: #ffffff00;
    /* Закрашиваем внутреннюю часть */
    border-color: #4a8b2f;
}

.form-check-input:checked::before {
    content: '';
    /* Добавляем контент для отображения псевдоэлемента */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Центрируем псевдоэлемент */
    width: 10px;
    /* Размер внутренней точки */
    height: 10px;
    background-color: #4a8b2f;
    /* Цвет внутренней точки */
    border-radius: 50%;
}

.loan-description {
    margin-left: 1%;
}

.form-select:focus {
    border-color: #f9f9f9;
    box-shadow: none;
}

select {
    background-color: #f9f9f9;
    /* Фон для самого выпадающего списка */
    color: #333;
    /* Цвет текста */
    padding: 10px;
    border-radius: 5px;
    height: auto;
}

/* Стили для отдельных пунктов */
select option {
    background-color: #fff;
    /* Фон для каждого <option> */
    color: #000;
    /* Цвет текста */
}

/* Стили для выделенного элемента */
select option:checked {
    background-color: #f9f9f9;
    /* Фон для выделенного <option> */
    color: black;
}

select option:hover {
    background-color: #ffc107 !important;
    /* Фон для выделенного <option> */
    color: black;
}

.result-box {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #ffffff;
    border-radius: 1.25rem;
    text-align: center;
    height: 19em;
    margin-top: 18%;
    background-image: url(https://cdn.molbulak.ru/data/assets/images/calc-bg.png/);
    background-size: contain;
}

.irs-single.static-value {
    /* position: absolute; */
    right: 0;
    /* top: 100%; */
    /* transform: translateY(0.5em); */

    padding: 0.5em 1em;
    border-radius: 1em;
    font-size: 0.9em;
    white-space: nowrap;
    margin-top: 1.1em;
    margin-left: 89%;
    width: auto;
    font-weight: 600;

}

.result-box .form-group {
    text-align: center;
    /* Центрируем содержимое внутри form-group */
}

.result-box .form-select {
    display: inline-block;
    /* Делаем select inline элементом */
    margin: 0 auto;
    /* Центрируем select */
}

.result-box p {
    margin: 0;
}

.calc-heading {
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: 1em !important;
}

.result-box .old-price {
    text-decoration: line-through;
    color: #6c757d;
    margin-top: 1em !important;
}

.result-box .new-price {
    color: #4a8b2f;
    font-size: 1.8rem;
    font-weight: bold;
    /*margin-top:1em;*/
}

.new-price-span {
    font-size: 0.8em;
    font-weight: 400;
}

.economy-price {
    font: 1.3em sans-serif;
    color: black;
    font-weight: 600;

}

.result-box .btn {
    margin-top: 20px;
    -webkit-box-shadow: 2px 7px 28px 7px rgb(42 43 44 / 20%);
    -moz-box-shadow: 2px 7px 28px 7px rgb(42 43 44 / 20%);
    box-shadow: 2px 7px 28px 7px rgb(42 43 44 / 20%);
    font-weight: 500;
}

.calc-button {
    background-color: #fcb814;
    padding: 1em;
    font-weight: 500;
    color: white;
    width: 100%;
    margin-top: 12%;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.range-slider {
    position: relative;
    margin-top: 10px;
}

.form-range:focus {
    border: 1px solid #7770 !important;
}

.range-value {
    font-size: 1.5em;
}

.range-slider input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: transparent;
    outline: none;
    padding: 0;
    margin: 0;
    z-index: 2;
    position: relative;
}

.range-slider input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: linear-gradient(to right, #ffc107 0%, #ffc107 var(--value), #e7e7e7 var(--value), #e7e7e7 100%);
    border-radius: 5px;
    border: none;
}

.range-slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    background: #ffc107;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #fff;
    position: relative;
    z-index: 3;
    margin-top: -8px;
    /* Center the thumb vertically */
}

.range-slider input[type="range"]::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: #ffc107;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #fff;
    position: relative;
    z-index: 3;
}

.range-slider input[type="range"]::-moz-range-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    background: linear-gradient(to right, #ffc107 0%, #ffc107 var(--value), #e7e7e7 var(--value), #e7e7e7 100%);
    border-radius: 5px;
    border: none;
}

.custom-control {
    width: 50%;
}

.custom-control-label {
    display: flex;
    align-items: center;
}

.custom-control-label::before {
    top: 0;
}

.custom-control-label::after {
    top: 0;
}

.custom-radio .custom-control-label::before,
.custom-radio .custom-control-label::after {
    width: 20px;
    height: 20px;
}

.custom-radio .custom-control-label::after {
    background-color: rgb(247 247 247);
    border-radius: 50px;
    border: solid 1px #ececec;
}

.level-dropdown {
    position: absolute;
    top: 20px;
    right: 20px;
    display: none;
}

.level-dropdown select {
    border: 2px solid #ffc107 !important;
    border-radius: 3em;
    padding: 5px 10px;
}

.level-dropdown select :active {
    border: 2px solid #ffc107;
    border-radius: 3em;
    padding: 5px 10px;
}

.level-dropdown select :focus-visible {
    border: 2px solid #ffc107;
    border-radius: 3em;
    padding: 5px 10px;
}

.gray-calc-text-sm {
    color: grey;
    font-size: 0.8em;
    font-weight: 500;
}

.radio-row {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
}

.custom-control-input:checked~.custom-control-label::after {
    background-color: green;
}

@media (max-width: 767px) {
    .custom-control {
        width: 100%;

    }

    .form-section {
        padding: 0px;
        border: none;
        /*background-color: #fff;
    -webkit-box-shadow: 2px 4px 17px 1px rgba(34, 60, 80, 0.19);
    -moz-box-shadow: 2px 4px 17px 1px rgba(34, 60, 80, 0.19);
    box-shadow: 2px 4px 17px 1px rgba(34, 60, 80, 0.19);*/
    }

    .irs-single.static-value {
        margin-left: 78%;
    }

    .form-check {
        width: 100%;
    }

    .result-box {
        height: 22em;
    }

}

/* Стили для кастомного селекта */
.custom-select {
    position: relative;
    width: 37%;
    margin-left: 1em;
    background-color: #f9f9f9;

}

.select-header {
    padding: 10px;
    border: 1px solid #cccccc38;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    -webkit-box-shadow: 2px 2px 5px 1px rgb(206 206 206 / 44%);
    -moz-box-shadow: 2px 2px 5px 1px rgb(206 206 206 / 44%);
    box-shadow: 2px 2px 5px 1px rgb(206 206 206 / 44%);

}


.select-header i {
    font-size: 12px;
}

.select-dropdown {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 10;
    max-height: 20rem;
    overflow-y: auto;
    margin-top: 5px;
    padding: 0;
    margin: 0;
    list-style: none;
}

.select-dropdown li {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.select-dropdown li:hover {
    background-color: #fcb814;
    color: white;
    width: 100%;
    box-sizing: border-box;
}

.arrow-up::before {
    content: '▲';
}

.arrow-down::before {
    content: '▼';
}

.sub-text {
    color: #777;
    font-size: 0.8em;
}

.level-select-drop {
    width: 94%;
    margin: 0 auto;
    margin-top: 5px;
}

@media (max-width: 767px) {
    .custom-select {
        width: 96%;
        margin: 0 auto;
    }
}
</style>
